<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header :breadcrumbs="breadcrumbs" :hiddenTab="true" :dense="true">
      <template v-slot:main>
        <v-menu close-on-click close-on-content-click offset-y :nudge-bottom="20" transition="slide-y-transition" absolute z-index="1000" min-width="145">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" v-bind="attrs" v-on="on" color="primary">
              <v-icon left>mdi-calendar-blank</v-icon>
             {{selectedDays}}
              <v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0 bgSearch">
            <v-list-item class="px-3" @click="selectedDays = 'Mes anterior'" active-class="transparent" :ripple="false">
              <v-list-item-title class="body-2">Mes anterior</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" @click="selectedDays = 'Antes de ayer'" active-class="transparent" :ripple="false">
              <v-list-item-title class="body-2">Antes de ayer</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </mini-header>
    <!-- end header -->
    <v-row no-gutters style="flex-wrap: nowrap;">
      <v-col cols="8" class="flex-grow-0 flex-shrink-0">
        <v-row>
          <v-col>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-card class="py-4 rounded-md" flat>
                  <v-card-title class="pa-0">
                    <v-toolbar color="transparent" flat height="30">
                      <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos recibidos</v-toolbar-title>
                    </v-toolbar>
                  </v-card-title>
                  <v-col class="px-5 pt-3 pb-0">
                    <span class="d-block text-h4">3.410
                      <span class="body-2">este mes</span>
                    </span>
                    <v-row no-gutters>
                      <span class="d-block text-h5">2.987
                        <span class="body-2">mes anterior</span>
                      </span>
                      <v-col :class="['pa-0 text-right', $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200 ? 'mt-n3' : '']">
                        <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                        <span class="success--text body-1 font-weight-bold">50%</span>
                        <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs mes anterior</span>
                      </v-col>
                    </v-row>
                    <v-btn class="body-2" :to="{ name: 'PurchaseList' }" color="primary" text small :ripple="false">Ver documentos</v-btn>
                  </v-col>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="py-4 rounded-md" flat>
                  <v-card-title class="pa-0">
                    <v-toolbar color="transparent" flat height="30">
                      <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Total documentos recibidos</v-toolbar-title>
                    </v-toolbar>
                  </v-card-title>
                  <v-col class="px-5 pt-3 pb-0">
                    <span class="d-block text-h4">$ 44.341
                      <span class="body-2">hoy</span>
                    </span>
                    <v-row no-gutters>
                      <span class="d-block text-h5">$ 47.237
                        <span class="body-2">ayer</span>
                      </span>
                      <v-col :class="['pa-0 text-right', $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200 ? 'mt-n3' : '']">
                        <v-icon class="rotate" size="25" color="red">mdi-chart-line-variant</v-icon>
                        <span class="red--text body-1 font-weight-bold">10.6%</span>
                        <span :class="['body-2 ml-1', {'d-block' : $store.state.base.isExpandNavigationDrawer || $vuetify.breakpoint.width <= 1200}]">vs ayer</span>
                      </v-col>
                    </v-row>
                    <v-btn class="body-2" :to="{ name: 'PurchaseList' }" color="primary" text small :ripple="false">Ver documentos</v-btn>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pl-0 pb-0">
            <v-card class="py-4 rounded-md" flat>
              <v-card-title class="pa-0">
                <v-toolbar color="transparent" flat height="30">
                  <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos recibidos por día</v-toolbar-title>
                </v-toolbar>
              </v-card-title>
              <v-col class="py-0 px-3 mt-n3">
                <chart-line
                  axe-type-y="number"
                  :chart-data="issueChartData"
                  chart-id="issueChartData"
                  :height="285"
                  :number-rounded="true"
                  :show-axe-x="true"
                  :show-draw-border-grid-lines-axe-y="true"
                  :show-grid-lines-axe-y="true"
                  :show-point="true"
                  :borderWidth="1"
                  :value-gradient="250"
                  :value-max-ticks-limit="5"
                  :zIndexTooltip="80"
                  style="position:relative;"
                />
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" style="min-width: 100px;" class="flex-grow-0 flex-shrink-1">
        <v-col class="pr-0 pt-0">
          <v-card class="pt-4 pb-2 rounded-md" flat>
            <v-card-title class="pa-0">
              <v-toolbar color="transparent" flat height="30">
                <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos recibidos por tipo</v-toolbar-title>
              </v-toolbar>
            </v-card-title>
            <v-row no-gutters align="center">
              <v-col cols="12" class="pa-0 text-center my-0">
                <section class="container-donut">
                  <chart-doughnut :chart-data="chartDonut3" :colors="['#0352CC', '#8BAFE8', '#023A91', '#023A91', '#B1C9EF']" :tooltips="true" id="donut-1" :height="167" />
                  <p class="label-donut secondary--text my-10">
                    <span :class="`${$vuetify.breakpoint.width <= 1200 ? 'body-1' : 'text-h4'} font-weight-bold`">23,4</span>
                    <span :class="`${$vuetify.breakpoint.width <= 1200 ? 'body-1' : 'subtitle-1'}`">mill</span>
                  </p>
                </section>
              </v-col>
              <v-col class="px-5">
                <v-list-item class="py-0">
                  <v-list-item-title class="d-flex align-center">
                    <v-avatar class="pb-1" size="10" color="primary" />
                    <span class="pl-2 body-1 fontDraw--text">Factura</span>
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <span cols="2" class="body-1 text-right fontDraw--text">(9.921)</span>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item class="py-0 mt-n3">
                  <v-list-item-title class="d-flex align-center">
                    <v-avatar class="pb-1" size="10" color="purpleMedium" />
                    <v-col cols="2" class="pl-2 body-1 fontDraw--text">Nota de crédito</v-col>
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <span cols="2" class="body-1 text-right fontDraw--text">(5.952)</span>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item class="py-0 mt-n4">
                  <v-list-item-title class="d-flex align-center">
                    <v-avatar class="pb-1" size="10" color="blueDarken" />
                    <v-col cols="2" class="pl-2 body-1 fontDraw--text">Factura exenta</v-col>
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <span cols="2" class="body-1 text-right fontDraw--text">(1.587)</span>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item class="py-0 mt-n4">
                  <v-list-item-title class="d-flex align-center">
                    <v-avatar class="pb-1" size="10" color="lightBlue" />
                    <v-col cols="2" class="pl-2 body-1 fontDraw--text">Factura de compra</v-col>
                  </v-list-item-title>
                  <v-list-item-action class="ma-0">
                    <span cols="2" class="body-1 text-right fontDraw--text">(1.587)</span>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
    <v-col cols="12" class="pa-0">
      <v-row class="mt-1">
        <v-col cols="6" class="pt-0 pr-0">
          <v-card class="py-4 rounded-md" flat>
            <v-card-title class="pa-0">
              <v-toolbar color="transparent" flat height="30">
                <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Total de documentos reclamados</v-toolbar-title>
              </v-toolbar>
            </v-card-title>
          <v-row class="mt-1 pb-3">
            <v-col class="pl-8 pt-3 pb-0">
              <span class="d-block text-h4">512
                <span class="body-2">este mes</span>
              </span>
              <v-row no-gutters>
                <span class="d-block text-h5">487
                  <span class="body-2">mes anterior</span>
                </span>
              </v-row>
              <v-col class="pa-0">
                <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                <span class="success--text body-1 font-weight-bold">6.3%</span>
                <span class="body-2 ml-1">vs mes anterior</span>
              </v-col>
            </v-col>
            <v-col class="pr-8 pt-3 pb-0">
              <span class="d-block text-h4">$12.310.123
                <span class="body-2">este mes</span>
              </span>
              <v-row no-gutters>
                <span class="d-block text-h5">$14.450.275
                  <span class="body-2">mes anterior</span>
                </span>
              </v-row>
              <v-col class="pa-0">
                <v-icon class="rotate" size="25" color="red">mdi-chart-line-variant</v-icon>
                <span class="red--text body-1 font-weight-bold">7.4%</span>
                <span class="body-2 ml-1">vs mes anterior</span>
              </v-col>
            </v-col>
          </v-row>
          </v-card>
        </v-col>
        <v-col cols="6" class="pt-0">
          <v-card class="py-4 rounded-md" flat>
            <v-card-title class="pa-0">
              <v-toolbar color="transparent" flat height="30">
                <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Total de documentos pendientes</v-toolbar-title>
              </v-toolbar>
            </v-card-title>
          <v-row class="mt-1 pb-3">
            <v-col class="pl-8 pt-3 pb-0">
              <span class="d-block text-h4">411
                <span class="body-2">este mes</span>
              </span>
              <v-row no-gutters>
                <span class="d-block text-h5">521
                  <span class="body-2">mes anterior</span>
                </span>
              </v-row>
              <v-col class="pa-0">
                <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                <span class="success--text body-1 font-weight-bold">6.3%</span>
                <span class="body-2 ml-1">vs mes anterior</span>
              </v-col>
            </v-col>
            <v-col class="pr-8 pt-3 pb-0">
              <span class="d-block text-h4">$4.310.459
                <span class="body-2">este mes</span>
              </span>
              <v-row no-gutters>
                <span class="d-block text-h5">$3.440.233
                  <span class="body-2">mes anterior</span>
                </span>
              </v-row>
              <v-col class="pa-0">
                <v-icon size="25" color="success">mdi-chart-line-variant</v-icon>
                <span class="success--text body-1 font-weight-bold">7.4%</span>
                <span class="body-2 ml-1">vs mes anterior</span>
              </v-col>
            </v-col>
          </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-row class="mt-3 pl-3">
      <v-col cols="4" class="pl-0 py-0">
        <v-card class="py-4 rounded-md" flat>
          <v-card-title class="pa-0">
            <v-toolbar color="transparent" flat height="30">
              <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos pendientes por día</v-toolbar-title>
            </v-toolbar>
          </v-card-title>
         <v-col class="mt-2 px-5">
          <v-simple-table class="table-detail table-dense no-hover" dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in list"
                  :key="item.label"
                >
                  <td class="body-2 pa-0" style="">{{ item.label }}</td>
                  <td class="body-2 text-center pa-0" style="width: 70%"><v-divider /></td>
                  <td class="text-right body-2 pa-0">{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
         </v-col>
        </v-card>
      </v-col>
      <v-col cols="4" class="pl-0 py-0">
        <v-card class="py-4 rounded-md" flat>
          <v-card-title class="pa-0">
            <v-toolbar color="transparent" flat height="30">
              <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos reclamados por tipo</v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-row class="mt-5 pb-2" no-gutters align="center">
            <v-col :cols="5" class="px-0 py-6 text-center">
              <div class="d-flex justify-center" style="width: 100%;">
                <section class="container-donut">
                  <chart-doughnut :chart-data="chartDonut" :colors="['#0352CC', '#8BAFE8', '#023A91', '#B1C9EF']" :tooltips="true" id="donut-1" :height="$vuetify.breakpoint.width <= 1080 ? 75 : 115" />
                  <p class="label-donut secondary--text my-10">
                    <span :class="`${$vuetify.breakpoint.width <= 1200 ? 'body-1' : 'text-h4'} font-weight-bold`">23,4</span>
                    <span :class="`${$vuetify.breakpoint.width <= 1200 ? 'body-2' : 'subtitle-1'}`">mill</span>
                  </p>
                </section>
              </div>
            </v-col>
            <v-col cols="6" class="pb-1 px-0">
              <v-simple-table class="table-detail table-dense no-hover" dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="body-2 pl-0 pr-1 pt-0"><v-avatar class="mt-n3" size="10" color="primary" /></td>
                      <td class="body-2 pa-0 pb-2 fontDraw--text" style="width: 130px; white-space: wrap !important;">Contenido del documento</td>
                      <td class="text-right body-2 pa-0 pb-2 fontDraw--text">(9.921)</td>
                    </tr>
                    <tr>
                      <td class="body-2 pl-0 pr-1 pt-0"><v-avatar class="mt-n3" size="10" color="purpleMedium" /></td>
                      <td class="body-2 pa-0 pb-2 fontDraw--text" style="width: 130px; white-space: wrap !important;">Falta parcial de mercadería</td>
                      <td class="text-right body-2 pb-2 pa-0 fontDraw--text">(5.952)</td>
                    </tr>
                    <tr>
                      <td class="body-2 pl-0 pr-1 pt-0"><v-avatar class="mt-n3" size="10" color="blueDarken" /></td>
                      <td class="body-2 pa-0 fontDraw--text" style="width: 130px; white-space: wrap !important;">Falta parcial de mercadería</td>
                      <td class="text-right body-2 pa-0 pb-2 fontDraw--text">(1.587)</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="4" class="pl-0 py-0">
        <v-card class="py-4 rounded-md" flat>
          <v-card-title class="pa-0">
            <v-toolbar color="transparent" flat height="30">
              <v-toolbar-title class="px-5 text-subtitle-2 fontBody--text font-weight-bold">Documentos según estado en SII</v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-row class="mt-5 pb-2" no-gutters align="center">
            <v-col :cols="5" class="px-0 py-6 text-center">
              <div class="d-flex justify-center" style="width: 100%;">
                <section class="container-donut">
                  <chart-doughnut :chart-data="chartDonut4" :colors="['#0352CC', '#8BAFE8', '#023A91', '#B1C9EF']" :tooltips="true" id="donut-1" :height="$vuetify.breakpoint.width <= 1080 ? 75 : 115" />
                  <p class="label-donut secondary--text my-10">
                    <span :class="`${$vuetify.breakpoint.width <= 1200 ? 'body-1' : 'text-h4'} font-weight-bold`">00,0</span>
                    <span :class="`${$vuetify.breakpoint.width <= 1200 ? 'body-2' : 'subtitle-1'}`">mill</span>
                  </p>
                </section>
              </div>
            </v-col>
            <v-col cols="6" class="pb-1 px-0">
              <v-simple-table class="table-detail table-dense no-hover" dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="body-2 pl-0 pr-1 pt-0"><v-avatar class="mt-n2" size="10" color="primary" /></td>
                      <td class="body-2 pa-0 pb-2 fontDraw--text" style="width: 130px; white-space: wrap !important;">Aceptado</td>
                      <td class="text-right body-2 pa-0 pb-2 fontDraw--text">(000.000)</td>
                    </tr>
                    <tr>
                      <td class="body-2 pl-0 pr-1 pt-0"><v-avatar class="mt-n2" size="10" color="purpleMedium" /></td>
                      <td class="body-2 pa-0 pb-2 fontDraw--text" style="width: 130px; white-space: wrap !important;">Reclamado</td>
                      <td class="text-right body-2 pb-2 pa-0 fontDraw--text">(000.000)</td>
                    </tr>
                    <tr>
                      <td class="body-2 pl-0 pr-1 pt-0"><v-avatar class="mt-n2" size="10" color="blueDarken" /></td>
                      <td class="body-2 pa-0 fontDraw--text" style="width: 130px; white-space: wrap !important;">Sin respuesta</td>
                      <td class="text-right body-2 pa-0 pb-2 fontDraw--text">(0.000)</td>
                    </tr>
                    <tr>
                      <td class="body-2 pl-0 pr-1 pt-0"><v-avatar class="mt-n2" size="10" color="error" /></td>
                      <td class="body-2 pa-0 fontDraw--text" style="width: 130px; white-space: wrap !important;">No registrado</td>
                      <td class="text-right body-2 pa-0 pb-2 fontDraw--text">(00)</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import ListViewMixin from '@/mixins/ListViewMixin'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import ChartBar from '@/components/charts/ChartBar'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ChartDoughnut from '@/components/charts/ChartDoughnut'
import ChartLine from '@/components/charts/ChartLine'

export default {
  components: {
    ChartDoughnut,
    VEmptyState,
    ChartBar,
    SkeletonList,
    ChartLine,
    MiniHeader
  },
  mixins: [
    ListViewMixin,
    GenericViewMixin
  ],
  data: () => ({
    list: [
      {
        label: '103',
        value: '1 día'
      },
      {
        label: '98',
        value: '2 días'
      },
      {
        label: '70',
        value: '3 días'
      },
      {
        label: '60',
        value: '4 días'
      },
      {
        label: '46',
        value: '5 días'
      },
      {
        label: '24',
        value: '6 días'
      },
      {
        label: '10',
        value: '7 días'
      },
      {
        label: '3',
        value: '8 días'
      }
    ],
    selectedDays: 'Mes actual',
    breadcrumbs: {
     main: 'Resumen',
     children: []
    },
    selected: 'this_day',
    filter: {
      this_day: 'Hoy',
      last_day: 'Ayer',
      this_week: 'Esta semana',
      last_week: 'Semana pasada',
      this_month: 'Este mes',
      last_month: 'Último mes'
    },
    cards: {},
    scroll: 0,
    loading: false,
    chartDonut: {
      labels: ['Factura', 'Nota de crédito', 'Factura exenta', 'Factura de compra'],
      datasets: [
        {
          data: [9921, 5952, 1587, 2382]
        }
      ]
    },
    chartDonut2: {
      labels: ['Contenido del documento', 'Falta parcial de mercadería', 'Falta total de mercadería'],
      datasets: [
        {
          data: [9921, 5952, 1587]
        }
      ]
    },
    chartDonut3: {
      labels: ['Aceptados', 'Reparados', 'Rechazados'],
      datasets: [
        {
          data: [9921, 5952, 1587]
        }
      ]
    },
    chartDonut4: {
      labels: ['Aceptado', 'Reclamado', 'Sin respuesta', 'No registrado'],
      datasets: [
        {
          data: [10, 0, 0, 0]
        }
      ]
    },
    chartBarData: {
      labels: [],
      datasets: [
        {
          data: []
        },
        {
          data: []
        }
      ]
    },
    documents: [],
    countAccepted: [],
    countRejected: [],
    dtes: {
      PE09: 'G.rem. remit.',
      PE31: 'G.rem. trasp.',
      PE01: 'Factura',
      PE03: 'Boleta venta',
      PE04: 'Liqui. Compra',
      PE07: 'Nota crédito',
      PE08: 'Nota débito',
      PE20: 'Comp. recep.',
      PE40: 'Comp. percep.'
    },
    issueChartData: {
      labels: [
        "00am",
        "04am",
        "08am",
        "12pm",
        "16pm",
        "20pm",
        "00am"
      ],
      datasets: [
        {
          label: ['$7.465.316'],
          backgroundColor: 'rgba(230, 238, 250, 1)',
          borderColor: '#0352CC',
          fill: true,
          // percent_change: '2.34',
          data: [90, 43, 70, 60, 89, 90, 100]
        }
      ]
    }
  }),
  computed: {
    totalDonut () {
      return (isAbbreviation = false) => {
        const total = ((this.documents[this.documents.length - 1]?.countAccepted ?? 0) + (this.documents[this.documents.length - 1]?.countRejected ?? 0)) || 0
        return isAbbreviation ? total.toString().length <= 6 ? total.toString() : (total / Math.pow(10, (Math.floor((Math.log10(total)) / 3) * 3))).toFixed(2) + ["", "", "M", "B", "T"][Math.floor(Math.log10(total) / 3)] : total ?? 0
      }
    }
  },
  watch: {
    '$route' () {
    },
    selected (val) {
      this.$router.replace({ query: { q: val} }).catch(() => {})
    }
  },
  created () {
    this.selected = this.$route?.query?.q ?? 'this_day'
  },
   methods: {
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    }
  }
}
</script>
